import {http} from "../api";

const easyUnpack = ({data}) => data;
export const BoatApi = {
    create_boat: {
        f: function create_boat(boat_name) {
            let url = '/boat/';
            return http.post(url, {boat_name}).then(easyUnpack)
        }
    },
    list_all_boats: {
        f: function list_all_boats() {
            let url = '/boat/';
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
};
