import React, {useEffect, useState} from "react";
import './historic-data-page.css';
import {DataSessionApi} from "../../api_client/generated/DataSession";
import SimpleButton from "../../component/simple-button/SimpleButton";
import InputField from "../../component/input-field/InputField";
import {Link} from "react-router-dom";

export default function HistoricDataPage() {
    const [dataSessions, setDataSessions] = useState(null);
    const [newSessionInfo, setNewSessionInfo] = useState({
        name: '',
        date: '',
        timestamp: '',
    });

    useEffect(() => {
        DataSessionApi.list_sessions.f().then(setDataSessions)
    }, []);

    return (<div className='historic-data-page'>
        <h1>Historic Data</h1>
        <Link to={'/admin/export'}>Export page</Link>
        <p>Select a session</p>
        {dataSessions !== null && <div className='sessions'>
            {dataSessions.filter(d => !d.is_test_session).sort((d1, d2) => d2.id-d1.id).map(session => <a key={session.id} href={`/session/${session.id}`} className='session'>
                <h4>{session.name}</h4>
            </a>)}
            {dataSessions.length === 0 && <p>No sessions found</p>}
        </div>}
        {dataSessions === null && <p>Loading...</p>}
        <div className='create-new-session'>
            <h4>Create a new session</h4>
            <InputField title='Session name'
                        onChanged={val => setNewSessionInfo(p => ({...p, name: val}))}
            />
            <InputField title='Date'
                        type='date'
                        onChanged={val => setNewSessionInfo(p => ({...p, date: val}))}
            />
            <InputField title='Timestamp'
                        type='time'
                        onChanged={val => setNewSessionInfo(p => ({...p, timestamp: val}))}
            />
            <SimpleButton value='Create New Session' onClick={() => {
                DataSessionApi.create_session.f(
                    newSessionInfo.name,
                    `${newSessionInfo.date}T${newSessionInfo.timestamp}:00Z`
                ).then(() => {
                    DataSessionApi.list_sessions.f().then(setDataSessions)
                })
            }} />
        </div>
    </div>)
}
