import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import * as d3 from 'd3';
import {transition} from 'd3-transition';
import {SessionContext} from "../../page/historic-data/session/SessionContextProvider";
import './line-chart.css';

export default function LineChart({
                                      id,
                                      all_data,
                                      data_key,
                                      areas,
                                      height = 400,
                                      width = 800,
                                      mean = null,
                                      std_deviation = null,
                                  }) {
    const chartRef = useRef();
    const [initialized, setInitialized] = useState(false);

    const {
        hoveringX,
        setHoveringX,
        selectedId,
        setSelectedId,
    } = useContext(SessionContext);

    const domain = useMemo(() => d3
        .scaleTime()
        .domain(d3.extent(all_data, (d) => d.properties.timestamp))
        .range([0, width - 70]), [all_data, width]);

    useEffect(() => {
        // Set dimensions and margins for the chart
        const margin = {top: 10, right: 30, bottom: 30, left: 40};
        const wantedWidth = width - margin.left - margin.right;
        const wantedHeight = height - margin.top - margin.bottom;

        // Remove any previous content inside the chart container
        d3.select(chartRef.current).selectChildren().remove();

        // Create the SVG element
        const svg = d3
            .select(chartRef.current)
            .append('svg')
            .attr('width', wantedWidth + margin.left + margin.right)
            .attr('height', wantedHeight + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Set the scales
        const x = d3
            .scaleTime()
            .domain(d3.extent(all_data, (d) => d.properties.timestamp))
            .range([0, width - 70])

        let maxY = d3.max(all_data, (d) => d.properties[data_key]);

        if (mean !== null && std_deviation !== null) {
            if (mean + std_deviation > maxY) {
                maxY = mean + std_deviation;
            }
        }

        maxY *= 1.1;

        const y = d3
            .scaleLinear()
            .domain([0, maxY])
            .range([wantedHeight, 0]);


        // Add the X axis
        svg
            .append('g')
            .attr('transform', `translate(0,${wantedHeight + 10})`)
            .call(d3.axisBottom(x).tickFormat(d3.timeFormat('%H:%M')))

        // Add the Y axis
        svg.append('g').call(d3.axisLeft(y));

        // Add the line
        svg
            .append('path')
            .datum(all_data)
            .attr('fill', 'none')
            .attr('stroke', 'steelblue')
            .attr('stroke-width', 1.5)
            .attr('d', d3
                .line()
                .x((d) => x(d.properties.timestamp))
                .y((d) => y(d.properties[data_key])));

        // svg
        //     .append('path')
        //     .datum(areas)
        //     .attr('fill', 'steelblue')
        //     .attr('fill-opacity', 0.3)
        //     .attr('d', d3
        //         .area()
        //         .x((d) => x(d.from))
        //         .y0(y(0))
        //         .y1(y(maxY))
        //     );

        if (mean !== null && std_deviation !== null) {
            // Add horizontal mean line
            svg
                .append('line')
                .attr('x1', 0)
                .attr('x2', wantedWidth)
                .attr('y1', y(mean))
                .attr('y2', y(mean))
                .attr('stroke', 'black')
                .attr('stroke-width', 1)
                .attr('stroke-dasharray', '5,5');
        }

        // Text top left that displays y value
        svg
            .append('text')
            .attr('id', `y-value-text-${id}`)
            .attr('x', 4)
            .attr('y', 0)
            .attr('dy', '1em')
            .attr('fill', 'black')
            .attr('font-size', '12px')
            .text(data_key);

        // text bottom right for the x value
        svg
            .append('text')
            .attr('id', `x-value-text-${id}`)
            .attr('x', wantedWidth - 30)
            .attr('y', wantedHeight + 10)
            .attr('dy', '-0.5em')
            .attr('fill', 'black')
            .attr('font-size', '12px')
            .text('Time');

        // Add the vertical line for hovering
        const selectedLine = svg
            .append('line')
            .attr('class', 'hover-line')
            .attr('id', `selected-line-${id}`)
            .attr('y1', 0)
            .attr('y2', wantedHeight)
            .attr('stroke', 'red')
            .attr('stroke-width', 1)
            .style('opacity', 0);  // Initially hidden

        // Add the vertical line for hovering
        const hoverLine = svg
            .append('line')
            .attr('class', 'hover-line')
            .attr('id', `hover-line-${id}`)
            .attr('y1', 0)
            .attr('y2', wantedHeight)
            .attr('stroke', 'black')
            .attr('stroke-width', 1)
            .style('opacity', 0);  // Initially hidden

        // Hover logic - send x-axis value to parent component
        svg
            .append('rect')
            .attr('width', wantedWidth)
            .attr('height', wantedHeight)
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .on('mousemove', function (event) {
                const x0 = x.invert(d3.pointer(event, this)[0]);

                // find closest data point
                const bisect = d3.bisector((d) => d.properties.timestamp).left;
                const i = bisect(all_data, x0, 1);
                const d0 = all_data[i - 1];
                if (i === all_data.length) {
                    setHoveringX(all_data[i - 1])
                    return;
                }
                const d1 = all_data[i];
                const dist0 = Math.abs(x0 - d0.properties.timestamp);
                const dist1 = Math.abs(x0 - d1.properties.timestamp);

                setHoveringX(dist0 > dist1 ? d1 : d0);
            })
            .on('click', (event) => {
                const x0 = x.invert(d3.pointer(event, this)[0]);

                // find closest data point
                const bisect = d3.bisector((d) => d.properties.timestamp).left;
                const i = bisect(all_data, x0, 1);
                const d0 = all_data[i - 1];
                if (i === all_data.length) {
                    setSelectedId(all_data[i - 1])
                    return;
                }
                const d1 = all_data[i];

                const dist0 = Math.abs(x0 - d0.properties.timestamp);
                const dist1 = Math.abs(x0 - d1.properties.timestamp);

                const d = dist0 > dist1 ? d1 : d0;
                setSelectedId(d);
            })
        setInitialized(true);
    }, [all_data, data_key, areas, height, width, setHoveringX, initialized, setSelectedId, id, mean, std_deviation]);

    useEffect(() => {
        if (!domain || !hoveringX) return;
        // Update the vertical line position based on hoveringX
        d3.select(`#hover-line-${id}`)
            .attr('x1', domain(hoveringX?.properties.timestamp))
            .attr('x2', domain(hoveringX?.properties.timestamp))
            .style('opacity', 1);

        d3.select(`#y-value-text-${id}`)
            .text(hoveringX?.properties[data_key]);

        let hours = String(hoveringX?.properties.timestamp.getHours()).padStart(2, '0');
        let minutes = String(hoveringX?.properties.timestamp.getMinutes()).padStart(2, '0');
        let formattedTime = `${hours}:${minutes}`;
        d3.select(`#x-value-text-${id}`)
            .text(formattedTime);
    }, [hoveringX, domain, id, data_key]);

    useEffect(() => {
        if (!domain) return;

        const t = transition().duration(200);

        d3.select(`#selected-line-${id}`)
            .style('opacity', selectedId ? 1 : 0)
            .transition(t)
            .attr('x1', domain(selectedId?.properties.timestamp))
            .attr('x2', domain(selectedId?.properties.timestamp))
    }, [selectedId, domain, id]);

    return <div className='line-chart' ref={chartRef}></div>;
};
