import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {DataSessionApi} from "../../../api_client/generated/DataSession";
import './session-page.css';
import InputField from "../../../component/input-field/InputField";
import {SensorReadingApi} from "../../../api_client/generated/SensorReading";
import ProteusMap from "../../../component/proteus-map/ProteusMap";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import SortableTable from "../../../component/sortable-table/SortableTable";
import InputCheckbox from "../../../component/InputCheckbox/InputCheckbox";
import LineChart from "../../../component/plots/LineChart";
import {SessionContext} from "./SessionContextProvider";
import BasicPopupWindow from "../../../component/basic-popup-window/BasicPopupWindow";
import ShowLessMore from "../../../component/show-lessmore/ShowLessMore";
import {WaterProfileMetricApi} from "../../../api_client/generated/WaterProfileMetric";
import {WaterProfileApi} from "../../../api_client/generated/WaterProfile";
import SelectedPointImage from "../../../component/selected-point-image/SelectedPointImage";
import burgerMenu from '../../../assets/burger-menu-icon.png';
import {calculateDistanceInKmBetweenLatLon} from "../../../component/proteus-map/NormalizeHeatmapData";

export default function SessionPage() {
    const [endDate, setEndDate] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [filters, setFilters] = useState({inWater: r => r.properties.conductivity > 100});

    const [smallGraphs, setSmallGraphs] = useState(true);
    const [sessionEdit, setSessionEdit] = useState(null);

    const [dataDescription, setDataDescription] = useState(null);

    const [waterProfiles, setWaterProfiles] = useState(null);
    const [selectedWaterProfile, setSelectedWaterProfile] = useState(null);
    const [waterMetrics, setWaterMetrics] = useState(null);

    const [selectedTab, setSelectedTab] = useState('detailed-view');
    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

    useEffect(() => {
        SensorReadingApi.describe_measurements.f({}).then(setDataDescription);
        WaterProfileApi.list_water_profiles.f().then(setWaterProfiles);
    }, []);

    const {
        session, setSession,
        sessionData, setSessionData,
        loadingData, setLoadingData,
        hoveringX, setHoveringX,
        selectedId, setSelectedId,
    } = useContext(SessionContext)

    const filteredSessionData = useMemo(() => {
        const res = sessionData?.filter(r => {
            return Object.entries(filters).every(([key, filter]) => {
                if (!filter) return true;
                return filter(r)
            })
        })
        if (!res) return null;

        if (res.length > 10) {
            return res.slice(5, res.length - 5)
        }
        return res.sort((a, b) => new Date(a.properties.timestamp) - new Date(b.properties.timestamp))
    }, [sessionData, filters]);

    const traveledDistance = useMemo(() => {
        if (!filteredSessionData) return 0;
        let distance = 0;
        let last = null;
        for (let current of filteredSessionData) {
            if (last) {
                distance += calculateDistanceInKmBetweenLatLon(last.properties.latitude, last.properties.longitude, current.properties.latitude, current.properties.longitude)
            }
            last = current;
        }
        return distance
    }, [filteredSessionData]);

    const inWaterPeriods = useMemo(() => {
        if (!filteredSessionData) return [];
        const periods = [];
        let currentPeriod = null;
        let currentRow;
        let dt;
        for (currentRow of filteredSessionData.sort((a, b) => new Date(a.properties.timestamp) - new Date(b.properties.timestamp))) {
            dt = new Date(currentRow.properties.timestamp)
            if (currentRow.properties.conductivity > 100 && currentPeriod === null) {
                currentPeriod = dt;
            } else {
                if (currentPeriod !== null) {
                    periods.push({
                        from: currentPeriod, to: dt,
                    });
                    currentPeriod = null;
                }
            }
        }
        if (currentPeriod !== null) {
            periods.push({
                from: currentPeriod, to: dt,
            });
        }
        return periods;
    }, [filteredSessionData]);

    const {sessionId} = useParams();

    const parameters = useMemo(() => {
        const k = [{
            key: 'timestamp_iso', title: 'Timestamp',
            dont_plot: true,
        }, {
            key: 'temperature', title: 'Temperature',
        }, {
            key: 'tryptophan', title: 'Tryptophan',
        }, {
            key: 'turbidity', title: 'Turbidity',
        }, {
            key: 'cdom', title: 'CDOM',
        }, {
            key: 'ph', title: 'pH',
        }, {
            key: 'salinity', title: 'Salinity',
        }, {
            key: 'bod', title: 'BOD',
        }, {
            key: 'faecal_coli', title: 'Faecal coli',
        }, {
            key: 'orp', title: 'ORP',
        }]
        if (waterMetrics === null) return k;

        return k.map(({key, title, ...rest}) => {
            const metric = waterMetrics.find(m => m.parameter === key);
            if (!metric) return {key, title, ...rest};
            return {
                key,
                title,
                metric,
                ...rest
            }
        })

    }, [waterMetrics]);

    useEffect(() => {
        DataSessionApi.get_session.f(sessionId).then(setSession);
    }, [sessionId, setSession]);

    const fetchData = useCallback(() => {
        if (!session) return;
        if (!session.session_end_timestamp) return;

        setLoadingData(true);

        SensorReadingApi.list_sensor_data.f({
            timestamp_from: session.session_start_timestamp,
            timestamp_to: session.session_end_timestamp,
            only_in_water: false,
        }).then(ds => {
            ds = ds.map(d => ({
                ...d,
                properties: {
                    ...d.properties,
                    timestamp: new Date(d.properties.timestamp),
                    timestamp_iso: new Date(d.properties.timestamp).toISOString(),
                }
            }))
            setSessionData(ds.sort((a, b) => new Date(a.properties.timestamp) - new Date(b.properties.timestamp)))
            setLoadingData(false);
        });
    }, [session, setLoadingData, setSessionData]);

    useEffect(() => {
        if (!session) return;
        if (!session.session_end_timestamp) return;
        console.log('fetching data');

        fetchData();
    }, [session, fetchData]);

    useEffect(() => {
        if (!selectedId) return;
        const row = document.getElementById(`row-${selectedId.id}`);
        if (!row) return;
        row.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
    }, [selectedId]);

    if (!session) return <div>Loading...</div>;

    return <div className='session-page'>
        <BasicPopupWindow className='edit-session-popup' showPopup={sessionEdit !== null}
                          closePopup={_ => setSessionEdit(null)}>
            {sessionEdit && <>
                <h2>Edit session</h2>
                <InputField title='Session start'
                            value={sessionEdit.session_start_timestamp}
                            type='datetime-local'
                            onChanged={val => {
                                setSessionEdit(p => ({...p, session_start_timestamp: val}))
                            }}/>
                <InputField title='Session end'
                            value={sessionEdit.session_end_timestamp}
                            type='datetime-local'
                            onChanged={val => {
                                setSessionEdit(p => ({...p, session_end_timestamp: val}))
                            }}/>
                <SimpleButton value='Save' onClick={_ => {
                    console.log('updating session', sessionEdit)
                    let timestamp_start = new Date(sessionEdit.session_start_timestamp)
                    timestamp_start = new Date(timestamp_start.setHours(timestamp_start.getHours() + 2))
                    let timestamp_end = new Date(sessionEdit.session_end_timestamp)
                    timestamp_end = new Date(timestamp_end.setHours(timestamp_end.getHours() + 2))
                    DataSessionApi.update_session.f(sessionId, {
                        timestamp_end,
                        timestamp_start,
                    }).then(() => {
                        DataSessionApi.get_session.f(sessionId).then(setSession);
                        setSessionEdit(null);
                    })
                }}/>
            </>}
        </BasicPopupWindow>
        <div className='session-header'>
            <div className='tab-wrapper'>
                <div className={`tab ${selectedTab === 'detailed-view' ? 'selected' : ''}`}
                     onClick={_ => setSelectedTab('detailed-view')}>Detailed view
                </div>
                <div className={`tab ${selectedTab === 'summary' ? 'selected' : ''}`}
                     onClick={_ => setSelectedTab('summary')}>Summary
                </div>
            </div>
            <div>
                <h2>{session.name}</h2>
                <sub>{session.session_start_timestamp} - {session.session_end_timestamp}</sub>
            </div>
            <div className='grower'/>
            <div className='burger-menu-wrapper'>
                <img className='drop-down-menu-button' alt='burger-menu-drop-down' src={burgerMenu} onClick={_ => setBurgerMenuOpen(p => !p)}/>
                {burgerMenuOpen && <div className='burger-menu'>
                    <button onClick={_ => {
                        setSessionEdit(session)
                    }}>Edit session
                    </button>
                    <label htmlFor='only-in-water'>Only in-water samples
                        <InputCheckbox
                            value={filters.inWater !== null}
                            id='only-in-water'
                            onChanged={checked => {
                                if (checked) {
                                    setFilters({...filters, inWater: r => r.properties.conductivity > 100})
                                } else {
                                    setFilters(prev => ({...prev, inWater: null}))
                                }
                            }}
                        />
                    </label>

                    <SimpleButton value='Export to CSV' onClick={_ => {
                        const keys = [...parameters.map(k => k.key), 'longitude', 'latitude'];
                        let csv = [...keys, 'QANiveau'].join(';') + '\n'
                        csv += filteredSessionData.map(d => keys.map(k => d.properties[k]).join(';') + `;Fagligt Forbehold`).join('\n')
                        const blob = new Blob([csv], {type: 'text/csv'})
                        const url = URL.createObjectURL(blob)
                        const a = document.createElement('a')
                        a.href = url
                        a.download = `${session.name}.csv`
                        a.click()
                        URL.revokeObjectURL(url)
                    }}/>
                    {session.session_end_timestamp === null && <div>
                        <InputField value={endDate}
                                    onChanged={setEndDate}
                                    title='Set end timestamp'
                                    type='date'
                        />
                        <InputField value={endTime}
                                    onChanged={setEndTime}
                                    type='time'
                        />
                        <input type='button' value='click to end' onClick={_ => {
                            DataSessionApi.update_session.f(sessionId, {
                                timestamp_end: `${endDate}T${endTime}:00Z`
                            }).then(() => {
                                DataSessionApi.get_session.f(sessionId).then(setSession);
                            })
                        }}/>
                    </div>}
                    <SimpleButton loading={loadingData} value='Get newest data' onClick={fetchData}/>

                    <InputField onlySelectableOptions
                                title={'Select water profile'}
                                options={waterProfiles?.map(wp => wp.name)}
                                value={selectedWaterProfile?.name}
                                onChanged={val => {
                                    const profile = waterProfiles.find(wp => wp.name === val);
                                    setSelectedWaterProfile(profile);
                                    WaterProfileMetricApi.list_water_profile_metrics.f(profile.id, {with_tags: true}).then(setWaterMetrics);
                                }}
                    />
                </div>}
            </div>
        </div>
        <main>
            <div className='left-side'>
                {selectedTab === 'summary' && filteredSessionData && <div className='summary'>
                    <p>Total traveled distance: {Math.round(traveledDistance*100)/100} km</p>
                    <p>Data points collected: {filteredSessionData.length}</p>
                    {parameters.filter(p => !p.dont_plot).map(({title, key, metric}) => {
                        const description = dataDescription ? dataDescription[key] : null;
                        const vals = filteredSessionData.map(d => d.properties[key]).sort((a, b) => a - b)
                        const mean = Math.floor(vals.reduce((a, b) => a + b, 0) / filteredSessionData.length * 100) / 100
                        const min = vals[0];
                        const max = vals[vals.length - 1];

                        // const median = vals[Math.floor(vals.length / 2)];
                        return <div key={key} className='parameter-wrapper'>
                            <h2>{title}</h2>
                            {description && <h4>{description.unit}</h4>}
                            <div className='horizontal'>
                                <div className='key-number-wrapper'>
                                    <h4>Measurements from this trip</h4>
                                    <div className='key-number'><label>Mean: {mean}</label></div>
                                    <div className='key-number'><label>Min: {min}</label></div>
                                    <div className='key-number'><label>Max: {max}</label></div>
                                    <LineChart
                                        id={key}
                                        width={355}
                                        height={150}
                                        data_key={key}
                                        all_data={filteredSessionData} areas={inWaterPeriods}
                                        mean={metric?.mean} std_deviation={metric?.std_deviation}
                                    />
                                </div>
                                {metric && <div className='key-number-wrapper metric'>
                                    <h4>Water profile</h4>
                                    <div className='key-number'><label>Mean: {metric.mean}</label></div>
                                    <div className='key-number'><label>Std deviation: {metric.std_deviation}</label>
                                    </div>
                                </div>}
                                {metric && <div className='key-number-wrapper metric'>
                                    <h4>Trip comparison</h4>
                                    <div className='key-number'>
                                        <label>Mean: {Math.floor(mean - metric.mean * 10) / 10}</label></div>
                                    <div className='key-number'>
                                        <label>Min: {Math.floor(min - metric.mean * 10) / 10}</label></div>
                                    <div className='key-number'>
                                        <label>Max: {Math.floor(max - metric.mean * 10) / 10}</label></div>
                                </div>}
                            </div>
                        </div>
                    })}
                </div>}
                {selectedTab === 'detailed-view' && <>
                    {filteredSessionData && filteredSessionData.length > 0 && <div className='map-video-wrapper'>
                        <ProteusMap sensorData={filteredSessionData}
                                    height={'45vh'}
                                    allow_pan={true}
                                    displayKeys={parameters.filter(p => !p.dont_plot).map(k => k.key)}
                                    selectedDatapoint={selectedId}
                                    setSelectedDatapoint={setSelectedId}
                        />
                        <div className='selected-point-images'>
                            <SelectedPointImage cameraId={'mast'}/>
                            <SelectedPointImage cameraId={'underwater'}/>
                        </div>
                    </div>}
                    {filteredSessionData && <SortableTable
                        items={filteredSessionData.map(d => ({...d, id: d.id}))}
                        fields={parameters}
                        customRenderFunction={(item, idx) => {
                            return (<tr key={item.id || idx}
                                        onClick={_ => setSelectedId(item)}
                                        id={`row-${item.id}`}
                                        className={selectedId && item.id === selectedId.id ? 'selected' : ''}>
                                <td>{item.properties.timestamp.toLocaleString()}</td>
                                {parameters.filter(p => p.dont_plot !== true).map(param => {
                                    if (param.dont_plot) return null;
                                    if (param.metric) {
                                        const diffFromMean = item.properties[param.key] - param.metric.mean;
                                        const minValue = param.metric.mean - param.metric.std_deviation * 2;
                                        const maxValue = param.metric.mean + param.metric.std_deviation * 2;
                                        const ratio = (diffFromMean - minValue) / (maxValue - minValue);

                                        return <td key={param.key}
                                                   onMouseEnter={_ => setHoveringX(item)}
                                                   style={{
                                                       background: `rgba(255, 0, 0, ${ratio})`,
                                                       color: ratio > 0.7 ? 'white' : 'black'
                                                   }}>
                                            {item.properties[param.key]}
                                        </td>
                                    }
                                    return <td key={param.key}
                                               onMouseEnter={_ => setHoveringX(item)}>
                                        {item.properties[param.key]}
                                    </td>
                                })}
                            </tr>)
                        }}
                    />}
                </>}
            </div>
            {selectedTab === 'detailed-view' && sessionData && sessionData.length > 0 && <div className='graph-wrapper'>
                <InputCheckbox value={smallGraphs} onChanged={setSmallGraphs} title='Small graphs'/>

                {filteredSessionData && filteredSessionData.length > 0 &&
                    <div className={`graphs ${smallGraphs ? 'small' : ''}`}>
                        {parameters.filter(p => !p.dont_plot).map(({title, key, metric}) => {
                            const description = dataDescription ? dataDescription[key] : null;

                            return (<div key={key} className=''>
                                <h2>{title}{description && <span>{description?.unit}</span>}</h2>
                                {description?.derived_from.length > 0 && <ShowLessMore>
                                    <h4>-> {description?.derived_from.join(', ')}</h4>
                                </ShowLessMore>}
                                <div className='key-number-wrapper'>
                                    {/*<label>avg: {Math.floor(filteredSessionData.map(d => d.properties[key]).reduce((a, b) => a + b, 0) / filteredSessionData.length * 10) / 10}</label>*/}
                                    {/*<label>min: {Math.min(...filteredSessionData.map(d => d.properties[key]))}</label>*/}
                                    {/*<label>max: {Math.max(...filteredSessionData.map(d => d.properties[key]))}</label>*/}
                                    {/*<label>Mean: {filteredSessionData.map(d => d.properties[key]).sort()[Math.floor(filteredSessionData.length / 2)]}</label>*/}
                                </div>
                                {/*{key !== 'turbidity' && <BoxPlot data={filteredSessionData.map(d => d.properties[key])}/>}*/}
                                <LineChart
                                    id={key}
                                    width={smallGraphs ? 250 : 450}
                                    height={smallGraphs ? 200 : 450}
                                    data_key={key}
                                    all_data={filteredSessionData} areas={inWaterPeriods}
                                    mean={metric?.mean} std_deviation={metric?.std_deviation}
                                />
                            </div>)
                        })}
                    </div>}
            </div>}
        </main>
    </div>
}
