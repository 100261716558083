import React, {useEffect, useMemo, useState} from 'react';
import './multi-select-field.css'
import check from '../../assets/checkmark.svg'
import arrow from '../../assets/arr_down.png';

export default function MultiSelectField({
                                             title,
                                             options,
                                             selected,
                                             onSelected,
                                             singleSelect = false,
                                             openUp = false,
                                             className,
                                             closeOnSelect = false
                                         }) {
    const [open, setOpen] = useState(false);
    const [uniqueOptions, setUniqueOptions] = useState([]);

    const selectedTitle = useMemo(() => {
        if (singleSelect) {
            return selected ? selected : title;
        }
        if (selected.length === 0) return title;
        if (selected.length <= 2) return selected.join(', ');
        return `${selected[0]} (+${selected.length - 1} other${selected.length > 2 ? 's' : ''})`
    }, [selected, singleSelect, title]);

    useEffect(() => {
        const opts = options.filter((o, idx) => o && options.indexOf(o) === idx)
        setUniqueOptions(opts)
    }, [options])

    return (<div tabIndex='0' className={`multi-select-field ${className}`} onBlur={() => setOpen(false)}>
        <div className={`option-wrapper ${open ? 'open' : 'closed'} ${openUp ? 'open-up' : ''}`}>
            {uniqueOptions.map((o, idx) =>
                <div key={idx}
                     onClick={() => onSelected(o) || (closeOnSelect && setOpen(false))}
                     className={`option ${selected.indexOf(o) !== -1 && 'selected'}`}>
                    <img src={check} alt='check'/>
                    <span>{o}</span>
                </div>
            )}
        </div>
        <div className='title' onClick={() => setOpen(!open)}>
            <span>{selectedTitle}</span>
            <img src={arrow} alt='open'/>
        </div>
    </div>)
}
