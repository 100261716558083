import React from "react";
import {Outlet} from "react-router-dom";
import './admin-page-wrapper.css';
import Header from "../../component/header/Header";

export default function AdminPageWrapper() {
    return (
        <main className='admin-page-wrapper'>
            <Header/>
            {/*<div className='admin-header'>*/}
            {/*    <b>Admin Page</b>*/}
            {/*    <a href={'/home'}>Home</a>*/}
            {/*    /!*<a href={'/admin/boat'}>Boat</a>*!/*/}
            {/*    /!*<a href={'/admin/video'}>Video</a>*!/*/}
            {/*</div>*/}
            <div className='content'>
                <Outlet/>
                <div className='spacer'/>
            </div>
        </main>
    );
}
