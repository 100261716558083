import React, {useEffect, useMemo, useState} from "react";
import {VideoApi} from "../../../api_client/generated/Video";
import InputField from "../../../component/input-field/InputField";
import BasicTable from "../../../component/basic-table/BasicTable";
import './admin-video-page.css';

export default function AdminVideoPage() {
    const [availableVideos, setAvailableVideos] = useState(null);
    const [boatId, setBoatId] = useState('');

    const [selectedVideo, setSelectedVideo] = useState(null);

    useEffect(() => {
        console.log('BOAT ID', boatId)
        VideoApi.list_available_videos.f({boat_id: boatId}).then(setAvailableVideos);
    }, [boatId]);

    const url = useMemo(() => {
        if (!selectedVideo) return null;
        const backendUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
        return `${backendUrl}/video/watch?boat_id=${selectedVideo?.boat_id}&camera_id=${selectedVideo?.camera_id}&timestamp=${selectedVideo?.timestamp}`;
    }, [selectedVideo]);

    return <div className='admin-video-page'>
        <InputField
            title='Boat'
            value={boatId}
            onChanged={setBoatId}
        />
        {availableVideos && <BasicTable>
            <thead>
            <tr>
                <th>Boat</th>
                <th>Camera</th>
                <th>Timestamp Started</th>
                <th>Frames</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(availableVideos).map(([boat, cameras]) =>
                Object.entries(cameras).map(([camera, timestamp]) =>
                    Object.entries(timestamp).map(([ts, count]) =>
                        <tr onClick={_ => setSelectedVideo({boat_id: boat, camera_id: camera, timestamp: ts})}
                            key={boat + camera + ts}>
                            <td>{boat}</td>
                            <td>{camera}</td>
                            <td>{ts}</td>
                            <td>{count}</td>
                        </tr>
                    )
                )
            )}
            </tbody>
        </BasicTable>}
        {url && <img className='video-stream' src={url} alt='video'/>}
    </div>
}
