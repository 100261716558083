import React, {useEffect, useState} from "react";
import {BoatApi} from "../../../api_client/generated/Boat";
import BasicTable from "../../../component/basic-table/BasicTable";

export default function AdminBoatPage() {
    const [boats, setBoats] = useState([])

    useEffect(() => {
        BoatApi.list_all_boats.f().then(setBoats)
    }, []);

    return (
        <div>
            <h1>Admin Boat Page</h1>
            <BasicTable>
                <thead>
                    <tr>
                        <th>Boat Name</th>
                        <th>Public key</th>
                    </tr>
                </thead>
                <tbody>
                {boats.map(boat => <tr key={boat.id}>
                    <td>{boat.name}</td>
                    <td>{boat.public_ssh_key}</td>
                </tr>)}
                </tbody>
            </BasicTable>
        </div>
    );
}
