import {http} from "../api";

const easyUnpack = ({data}) => data;
export const WaterProfileApi = {
    create_water_profile: {
        f: function create_water_profile(name) {
            let url = '/water-profile';
            return http.post(url, {name}).then(easyUnpack)
        }
    },
    list_water_profiles: {
        f: function list_water_profiles() {
            let url = '/water-profile';
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    get_water_profile_by_id: {
        f: function get_water_profile_by_id(id) {
            let url = '/water-profile/$id';
            let parameterNames = ["$id"];
            [id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    update_water_profile: {
        f: function update_water_profile(id, name) {
            let url = '/water-profile/$id';
            let parameterNames = ["$id"];
            [id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.put(url, {name}).then(easyUnpack)
        }
    },
    delete_water_profile: {
        f: function delete_water_profile(id) {
            let url = '/water-profile/$id';
            let parameterNames = ["$id"];
            [id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.delete(url, {data: {}}).then(easyUnpack)
        }
    },
};
