import React, {useCallback, useEffect, useRef, useState} from 'react';
import Map from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import { defaults as defaultInteractions } from 'ol/interaction';
import {Zoom} from "ol/control";

export default function useOlMap(){
    const [map, setMap] = useState(null);
    const [initialized, setInitialized] = useState(null);

    const [initialSourceRender, setInitialSourceRender] = useState(null);

    const mapElement = useRef();

    useEffect(() => {
        if (!mapElement.current || !initialSourceRender || initialized) return;

        const initialLayer = new VectorLayer({
            source: initialSourceRender
        })
        const initialMap = new Map({
            target: mapElement.current,
            layers: [],
            view: new View({
                projection: 'EPSG:3857',
                center: [15, 44],
                zoom: 6,
            }),
            controls: [],
            interactions: defaultInteractions({
                dragPan: false,     // Disable drag panning
                dragRotate: false,  // Disable drag rotation
            }),
        })

        // Add zoom controls
        initialMap.addControl(new Zoom());

        initialMap.getView().fit(initialLayer.getSource().getExtent(), {
            padding: [50, 50, 50, 50],
            maxZoom: 18,
        })
        setInitialized(true);
        setMap(initialMap);
    }, [mapElement, initialSourceRender, initialized]);

    const addLayer = useCallback((layer) => {
        const currentLayers = map.getLayers().getArray();
        if (currentLayers.includes(layer)) return;
        map.addLayer(layer);
    }, [map]);

    const removeLayer = useCallback((layer) => {
        const currentLayers = map.getLayers().getArray();
        if (!currentLayers.includes(layer)) return;
        map.removeLayer(layer);
    }, [map]);

    return {
        map,
        mapElement,
        initialized,
        setInitialSourceRender,
        addLayer,
        removeLayer,
    }
}
