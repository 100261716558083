import axios from 'axios';


export const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000',
    timeout: 45000
})

http.interceptors.request.use((config) => {
    if (config.url === '/user/authenticate') return config;

    const token = localStorage.getItem('token')
    if (token) {
        const expires = localStorage.getItem('tokenExpires')
        if (expires) {
            if (new Date(expires) < new Date()) {
                localStorage.removeItem('token')
                localStorage.removeItem('tokenExpires')
                throw new axios.Cancel('Token expired. Moving to login.');
            }
        }
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
})

export const easyUnpack = ({data}) => data

export const formatError = (response) => {
    const error = new Error(
        response.message ||
        'An unknown error occured'
    )
    error.payload = response.message

    throw error
}
