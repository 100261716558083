import {http} from "../api";

const easyUnpack = ({data}) => data;
export const UserApi = {
    authenticate: {
        f: function authenticate(username, password) {
            let url = '/user/authenticate';
            return http.post(url, {username, password}).then(easyUnpack)
        }
    },
    create_invitation: {
        f: function create_invitation(username, email, scope) {
            let url = '/user/admin/create-invitation';
            return http.post(url, {username, email, scope}).then(easyUnpack)
        }
    },
    accept_invitation: {
        f: function accept_invitation(token, password) {
            let url = '/user/accept-invitation';
            return http.post(url, {token, password}).then(easyUnpack)
        }
    },
    check_invitation_token_validity: {
        f: function check_invitation_token_validity(token) {
            let url = '/user/check-invitation-token';
            return http.post(url, {token}).then(easyUnpack)
        }
    },
    accept_pw_reset: {
        f: function accept_pw_reset(token, password) {
            let url = '/user/reset-pw';
            return http.post(url, {token, password}).then(easyUnpack)
        }
    },
    create_request_pw_reset: {
        f: function create_request_pw_reset(reset_pw_user_id) {
            let url = '/user/request-pw-reset';
            return http.post(url, {reset_pw_user_id}).then(easyUnpack)
        }
    },
    list_users: {
        f: function list_users() {
            let url = '/user/list';
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    list_invitations: {
        f: function list_invitations() {
            let url = '/user/invitations';
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
};
