import React, {useMemo, useState} from 'react';
import './data-calculator.css';
import InputField from "../../component/input-field/InputField";


function makePrettyDataRate(bytes, pr_second=false) {
    if (bytes < 1_000) {
        return `${bytes} B/s`;
    } else if (bytes < 1_000_000) {
        return `${(bytes / 1_000).toFixed(2)} KB${pr_second ? '/s' : ''}`;
    } else if(bytes < 1_000_000_000) {
        return `${(bytes / 1_000_000).toFixed(2)} MB${pr_second ? '/s' : ''}`;
    } else {
        return `${(bytes / 1_000_000_000).toFixed(2)} GB${pr_second ? '/s' : ''}`;
    }
}

export default function DataCalculator() {
    const [cameras, setCameras] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [duration, setDuration] = useState(60);

    const [newSensorInfo, setNewSensorInfo] = useState({name: '', updateRate: 0, bytesPerUpdate: 0});

    const [positionalDataRate, setPositionalDataRate] = useState({
        GPS: {updatesPerSecond: 1, bytesPerUpdate: 40},
        IMU: {updatesPerSecond: 0.2, bytesPerUpdate: 40},
        "CAMERA (1080p)": {updatesPerSecond: 24, bytesPerUpdate: 1080 * 1920},
        "CAMERA (720p)": {updatesPerSecond: 24, bytesPerUpdate: 720 * 1280},
        "CAMERA (480px)": {updatesPerSecond: 24, bytesPerUpdate: 480 * 640},
    });

    const addCamera = (resolution, frameRate, bitrate) => {
        setCameras([...cameras, {resolution, frameRate, bitrate}]);
    };

    const removeCamera = (index) => {
        const newCameras = cameras.filter((_, i) => i !== index);
        setCameras(newCameras);
    };

    const addSensor = (name, updateRate, bytesPerUpdate) => {
        setSensors([...sensors, {name, updateRate, bytesPerUpdate}]);
    };

    const removeSensor = (name) => {
        const newSensors = sensors.filter(sensor => sensor.name !== name);
        setSensors(newSensors);
    };

    const {realTimeUsage, storedUsage} = useMemo(() => {
        let totalDataUsageRealTime = 0;
        let totalDataUsageStored = 0;

        // Calculate positional data usage
        for (const rate of Object.values(positionalDataRate)) {
            const {updatesPerSecond, bytesPerUpdate} = rate;
            const dataUsage = updatesPerSecond * bytesPerUpdate;
            totalDataUsageRealTime += dataUsage;
        }

        // Calculate sensor data usage
        for (const sensor of sensors) {
            const {updateRate, bytesPerUpdate} = sensor;
            const dataUsage = updateRate * bytesPerUpdate * 60 * duration;
            totalDataUsageStored += dataUsage;
        }

        // Calculate camera data usage
        for (const camera of cameras) {
            const bitrate = (camera.bitrate * 1_000_000) / 8;  // Convert Mbps to bytes per second
            const dataUsageRealTime = bitrate * 60 * duration;
            const dataUsageStored = dataUsageRealTime * 5;  // Assume stored video is 5 times higher bitrate
            totalDataUsageStored += dataUsageStored;
        }

        return {
            realTimeUsage: (totalDataUsageRealTime).toFixed(2),
            storedUsage: (totalDataUsageStored).toFixed(2)
        }
    }, [cameras, sensors, duration, positionalDataRate]);

    return (
        <div className='data-calculator'>
            <h1>Sensor Robot Data Usage Calculator</h1>

            <h1>Duration based</h1>
            <div className='horizontal'>
                <div>
                    <button onClick={() => addCamera('480p', 15, 1)}>Add 480p Camera</button>
                    <button onClick={() => addCamera('1080p', 30, 5)}>Add 1080p Camera</button>
                    <h2>Cameras</h2>
                    {cameras.map((camera, index) => (
                        <div key={index}>
                            <p>{camera.resolution} | {camera.frameRate} fps | {camera.bitrate} Mbps</p>
                            <button onClick={() => removeCamera(index)}>Remove Camera</button>
                        </div>
                    ))}
                </div>

                <div>
                    <InputField title='Sensor name' value={newSensorInfo.name}
                                onChanged={(name) => setNewSensorInfo({...newSensorInfo, name})}/>
                    <InputField title='Update rate (pr second)' value={newSensorInfo.updateRate}
                                onChanged={(updateRate) => setNewSensorInfo({...newSensorInfo, updateRate})}/>
                    <InputField title='Bytes per update' value={newSensorInfo.bytesPerUpdate}
                                onChanged={(bytesPerUpdate) => setNewSensorInfo({...newSensorInfo, bytesPerUpdate})}/>
                    <button
                        onClick={() => addSensor(newSensorInfo.name, newSensorInfo.updateRate, newSensorInfo.bytesPerUpdate)}>Add
                        Sensor
                    </button>
                    <h2>Sensors</h2>
                    {sensors.map((sensor, index) => (
                        <div key={index}>
                            <p>{sensor.name} | {sensor.updateRate} updates/sec
                                | {sensor.bytesPerUpdate} bytes/update</p>
                            <button onClick={() => removeSensor(sensor.name)}>Remove Sensor</button>
                        </div>
                    ))}
                </div>
            </div>

            <div>
                <h2>Duration</h2>
                <input
                    type="number"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    min="1"
                />
                <span> minutes</span>
            </div>

            <div>
                <h1>Real time</h1>
                <div className='real-time-entries'>
                    {Object.entries(positionalDataRate).map(([key, rate]) => (
                        <div key={key}>
                            <h3>{key}</h3>
                            <p>Updates per second: <input type='number'
                                                          value={rate.updatesPerSecond}
                                                          onChange={e => setPositionalDataRate(prev => ({
                                                              ...prev,
                                                              [key]: {...prev[key], updatesPerSecond: e.target.value}
                                                          }))}/></p>
                            <p>Bytes per update: <input type='number'
                                                        value={rate.bytesPerUpdate}
                                                        onChange={e => setPositionalDataRate(prev => ({
                                                            ...prev,
                                                            [key]: {...prev[key], bytesPerUpdate: e.target.value}
                                                        }))}/></p>
                            <p>Pr second: {makePrettyDataRate(rate.updatesPerSecond * rate.bytesPerUpdate, true)}</p>
                        </div>
                    ))}
                </div>
            </div>

            <hr/>
            <div>
                <h2>Data Usage</h2>
                <p>Real-Time Data Usage: {makePrettyDataRate(realTimeUsage, true)}</p>
                <p>Stored Data Usage: {makePrettyDataRate(storedUsage)}</p>
            </div>
        </div>
    );
};
