import React, {useContext, useEffect, useState} from "react";
import {SessionContext} from "../../page/historic-data/session/SessionContextProvider";
import {BoatImageApi} from "../../api_client/generated/BoatImage";
import './selected-point-wrapper.css';

export default function SelectedPointImage({cameraId}) {
    const [signedUrl, setSignedUrl] = useState(null);
    const [largeImage, setLargeImage] = useState(false);
    const [loading, setLoading] = useState(false);

    const [displayingVideo, setDisplayingVideo] = useState(false);

    const {selectedId} = useContext(SessionContext);

    useEffect(() => {
        if (selectedId === null) {
            setSignedUrl(null);
            return;
        }

        setLoading(true);
        let timestamp = selectedId.properties.timestamp;
        timestamp = new Date(new Date(timestamp).setHours(timestamp.getHours() + 1));

        // Get the signed URL for the image
        BoatImageApi.get_relevant_image.f(cameraId, timestamp, 10).then(u => {
            setSignedUrl(u)
            setLoading(false);
        }).catch(_ => {
            setSignedUrl(null);
            setLoading(false);
        });
    }, [selectedId, cameraId]);

    if (selectedId === null || signedUrl === null) {
        return null;
    }

    return <div className={`selected-point-wrapper ${largeImage ? 'large' : ''}`}>
        {!displayingVideo && <img src={signedUrl.signed_url} alt='Selected point' onClick={_ => setLargeImage(p => !p)}/>}

        {/*x-mixed-replace*/}
        {displayingVideo && <img src={`${process.env.REACT_APP_API_URL}/video/watch?boat_id=steelBoat1&camera_id=${cameraId}&first_image_id=${signedUrl.id}`}
                                alt='Selected point' onClick={_ => setLargeImage(p => !p)}/>}
        {loading && <div className='loading-label'><p>Loading {cameraId}...</p></div>}
        {!largeImage && <div className='hover-label'>{cameraId} {signedUrl.timestamp}</div>}
        {<div className='hover-label start-stop'><button onClick={_ => setDisplayingVideo(p => !p)}>{displayingVideo ? 'stop' : 'start'}</button></div>}
    </div>
}
