import React, {useEffect, useState} from "react";
import './water-profile-page.css'
import {useParams} from "react-router-dom";
import {WaterProfileApi} from "../../../api_client/generated/WaterProfile";
import {WaterProfileMetricApi} from "../../../api_client/generated/WaterProfileMetric";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import BasicPopupWindow from "../../../component/basic-popup-window/BasicPopupWindow";
import PlotNormalFunction from "../../../component/plots/NormalFunction";
import InputField from "../../../component/input-field/InputField";

export default function WaterProfilePage() {
    const [waterProfile, setWaterProfile] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(false);

    const [selectedMetric, setSelectedMetric] = useState(null);
    const [selectedMetricTags, setSelectedMetricTags] = useState(null);

    const [showAddMetricPopup, setShowAddMetricPopup] = useState(false);
    const [showAddTagPopup, setShowAddTagPopup] = useState(false);

    const [editingTag, setEditingTag] = useState(null);

    const {profileId} = useParams();

    useEffect(() => {
        setLoading(true);
        WaterProfileApi.get_water_profile_by_id.f(profileId).then(vals => {
            setWaterProfile(vals);
            setLoading(false);
        });
        WaterProfileMetricApi.list_water_profile_metrics.f(profileId, {}).then(setMetrics);
    }, [profileId]);

    useEffect(() => {
        if (selectedMetric === null) return;
        WaterProfileMetricApi.list_water_profile_metric_tags.f(profileId, selectedMetric.id).then(setSelectedMetricTags);
    }, [selectedMetric, profileId]);

    if (loading || waterProfile === null) {
        return <div>Loading...</div>
    }

    return (<div className='water-profile-page'>
            <BasicPopupWindow showPopup={showAddMetricPopup}
                              closePopup={setShowAddMetricPopup}
                              className='basic-form-popup'>
                <h1>Add Metric</h1>
                <form onSubmit={e => {
                    e.preventDefault();
                    const formData = new FormData(e.target);
                    const parameter = formData.get('parameter');
                    const parameter_unit = formData.get('parameter_unit');
                    const mean = formData.get('mean');
                    const std_deviation = formData.get('std_deviation');
                    WaterProfileMetricApi.create_water_profile_metric.f(profileId, parameter, parameter_unit, mean, std_deviation).then(() => {
                        WaterProfileMetricApi.list_water_profile_metrics.f(profileId, {}).then(setMetrics);
                        setShowAddMetricPopup(false);
                    });
                }}>
                    <label>
                        Parameter
                        <input type='text' name='parameter' required/>
                    </label>
                    <label>
                        Parameter Unit
                        <input type='text' name='parameter_unit' required/>
                    </label>
                    <label>
                        Mean
                        <input type='number' name='mean' required/>
                    </label>
                    <label>
                        Standard Deviation
                        <input type='number' name='std_deviation' required/>
                    </label>
                    <button type='submit'>Add Metric</button>
                </form>
            </BasicPopupWindow>
            {selectedMetric && <BasicPopupWindow showPopup={showAddTagPopup}
                                                 closePopup={setShowAddTagPopup}
                                                 className='basic-form-popup'>
                <h1>Add Tag</h1>
                <subtitle>{selectedMetric.parameter_unit}</subtitle>
                <form onSubmit={e => {
                    e.preventDefault();
                    const formData = new FormData(e.target);
                    const tag_title = formData.get('tag_title');
                    const lower_threshold = formData.get('lower_threshold');
                    const upper_threshold = formData.get('upper_threshold');
                    if (!lower_threshold && !upper_threshold) {
                        alert('At least one threshold must be set');
                        return;
                    }
                    WaterProfileMetricApi.create_water_profile_metric_tag.f(profileId, selectedMetric.id, tag_title, {
                        lower_threshold: lower_threshold,
                        upper_threshold: upper_threshold
                    }).then(() => {
                        WaterProfileMetricApi.list_water_profile_metric_tags.f(profileId, selectedMetric.id).then(setSelectedMetricTags);
                        setShowAddTagPopup(false);
                    });
                }}>
                    <label>
                        Tag Title
                        <input type='text' name='tag_title' required/>
                    </label>
                    <label>
                        Lower Threshold
                        <input type='number' name='lower_threshold'/>
                    </label>
                    <label>
                        Upper Threshold
                        <input type='number' name='upper_threshold'/>
                    </label>
                    <button type='submit'>Add Tag</button>
                </form>

            </BasicPopupWindow>}
            <h1>{waterProfile.name}</h1>
            <main>
                <div className='metrics'>
                    <h2>Metrics</h2>
                    {metrics !== null && metrics.map(metric => <div key={metric.id} className='metric'
                                                                    onClick={_ => setSelectedMetric({
                                                                        ...metric,
                                                                        original_mean: metric.mean,
                                                                        original_std_deviation: metric.std_deviation,
                                                                    })}>
                        <h4>{metric.parameter}</h4>
                    </div>)}
                    {metrics === null && <p>Loading...</p>}
                    <div className='grower'>&nbsp;</div>
                    <SimpleButton onClick={_ => setShowAddMetricPopup(true)} value='Add Metric'/>
                </div>
                {selectedMetric !== null && <>
                    <div className='selected-metric'>
                        <h3>{selectedMetric.parameter}</h3>
                        <InputField
                            title={'Mean'}
                            value={selectedMetric.mean}
                            onChanged={val => {
                                setSelectedMetric({...selectedMetric, mean: val})
                            }}/>
                        <InputField
                            title={'Standard Deviation'}
                            value={selectedMetric.std_deviation}
                            onChanged={val => {
                                setSelectedMetric({...selectedMetric, std_deviation: val})
                            }}/>
                        <PlotNormalFunction mean={selectedMetric.mean} std_deviation={selectedMetric.std_deviation}
                                            wanted_width={360} wanted_height={220}/>
                        {(selectedMetric && (selectedMetric.mean !== selectedMetric.original_mean ||
                            selectedMetric.std_deviation !== selectedMetric.original_std_deviation)) &&
                        <SimpleButton value='Save' onClick={_ => {
                            WaterProfileMetricApi.update_water_profile_metric.f(profileId, selectedMetric.id, {
                                mean: selectedMetric.mean,
                                std_deviation: selectedMetric.std_deviation
                            }).then(() => {
                                WaterProfileMetricApi.list_water_profile_metrics.f(profileId, {}).then(setMetrics);
                                setSelectedMetric({
                                    ...selectedMetric,
                                    original_mean: selectedMetric.mean,
                                    original_std_deviation: selectedMetric.std_deviation
                                });
                            });
                        }} />}
                    </div>
                    <div className='selected-metric-tags'>
                        <h2>Tags</h2>
                        {selectedMetricTags !== null && selectedMetricTags.map(tag => <div key={tag.id} className='tag'>
                            <h4>{tag.tag_title}</h4>
                            <p>Lower Threshold: {tag.lower_threshold}</p>
                            <p>Upper Threshold: {tag.upper_threshold}</p>
                        </div>)}
                        {selectedMetricTags !== null && selectedMetricTags.length === 0 && <p>No tags</p>}
                        <div className='grower'>&nbsp;</div>
                        <SimpleButton value='Add Tag' onClick={_ => setShowAddTagPopup(true)}/>
                    </div>
                </>}
            </main>
        </div>
    )
}
