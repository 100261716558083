import React, {useMemo} from "react";
import InputCheckbox from "../../../../component/InputCheckbox/InputCheckbox";
import './data-filters.css';
import MultiSelectField from "../../../../component/multi-select-field/multi-select-field";
import InputField from "../../../../component/input-field/InputField";

export default function DataFilters({
                                        initialFilters,
                                        updateFilter,
                                        dataSessions,
                                        keys,
                                        setKeys,
                                        children,
                                    }) {
    const availableKeys = useMemo(() => [
        'timestamp',
        'time',
        'temperature',
        'e_coli',
        'faecal_coli',
        'total_coli',
        'tryptophan',
        'turbidity',
        'bod',
        'cdom',
        'orp',
        'ph',
        // 'ph_mV',
        'conductivity',
        'salinity',
        'latitude',
        'longitude',
    ], []);

    return (<div className='data-filters'>
        <select value={initialFilters.data_session_id}
                onChange={(e) => updateFilter('data_session_id', e.target.value)}>
            <option value={''}>Select data session</option>
            <option value={'all'}>All data ever</option>
            {dataSessions.map((dataSession) => <option key={dataSession.id}
                                                       value={dataSession.id}>{dataSession.name}</option>)}
        </select>

        <InputField value={initialFilters.timestamp_from}
                    title='From'
                    type='datetime-local'
                    onChanged={v => updateFilter('timestamp_from', v)}/>

        <InputField value={initialFilters.timestamp_to}
                    title='To'
                    type='datetime-local'
                    onChanged={v => updateFilter('timestamp_to', v)}/>

        <InputCheckbox title='In water'
                       value={initialFilters.in_water}
                       onChanged={v => updateFilter('in_water', v)}/>

        <MultiSelectField selected={keys}
                          options={availableKeys}
                          onSelected={val => {
                              if (keys.includes(val)) {
                                  setKeys(keys.filter(k => k !== val));
                              } else {
                                  setKeys([...keys, val].sort((a, b) => availableKeys.indexOf(a) - availableKeys.indexOf(b)));
                              }
                          }}/>
        {children}
    </div>)
}
