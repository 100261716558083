import React from 'react'
import {Navigate} from "react-router-dom";
import {logout} from "./user-service";
import './private-route.css'
import Header from "../component/header/Header";

export default function RequireAuth({
                                        children,
                                        useMaxWidth = true,
                                        noHeader = false,
                                        noFooter = false,
                                        extraClass = null
                                    }) {
    const expires = localStorage.getItem('tokenExpires')

    if (new Date(expires) < new Date()) {
        logout()
        return <Navigate to='/login'/>
    }

    return <div className={`private-route ${useMaxWidth ? 'max-width' : ''} ${extraClass ? extraClass : ''}`}>
        {children}
    </div>
}
