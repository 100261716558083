import React from 'react'
import './simple-button.css'

export default function SimpleButton({
                                         className,
                                         value,
                                         onClick,
                                         hidden = false,
                                         disabled = false,
                                         loading = false,
                                         big = false,
                                         round,
                                         red = false
                                     }) {
    return <input
        hidden={hidden}
        type='button'
        className={`simple-button ${className} ${red ? 'red' : ''} ${round ? 'round' : ''} ${disabled ? 'disabled' : ''} ${loading && 'loading'} ${big ? 'big' : ''}`}
        disabled={disabled}
        value={loading ? 'Loading...' : value}
        onClick={() => {
            if (loading) return;
            onClick()
        }}/>
}
