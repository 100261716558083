import React, {useEffect, useState} from "react";
import SimpleButton from "../../component/simple-button/SimpleButton";
import InputField from "../../component/input-field/InputField";
import {WaterProfileApi} from "../../api_client/generated/WaterProfile";
import './water-profile-overview.css';
import {Outlet} from "react-router-dom";

export default function WaterProfileOverview() {
    const [waterProfiles, setWaterProfiles] = useState(null);
    const [newWaterProfileName, setNewWaterProfileName] = useState('');

    useEffect(() => {
        WaterProfileApi.list_water_profiles.f().then(setWaterProfiles)
    }, []);

    return (<div className='water-profile-overview'>
        <h1>Water profiles</h1>
        <div className='create-new-session'>
            <h4>Create new water profile</h4>
            <InputField title='Profile name'
                        onChanged={setNewWaterProfileName}
            />
            <SimpleButton value='Create new water profile' onClick={() => {
                WaterProfileApi.create_water_profile.f(
                    newWaterProfileName
                ).then(() => {
                    WaterProfileApi.list_water_profiles.f().then(setWaterProfiles)
                })
            }}/>
        </div>
        <p>Select a profile</p>
        {waterProfiles !== null && <div className='profiles'>
            {waterProfiles.sort((d1, d2) => d2.id - d1.id).map(profile => <a key={profile.id}
                                                                             href={`/water-profile/${profile.id}`}
                                                                             className='profile'>
                <h4>{profile.name}</h4>
            </a>)}
            {waterProfiles.length === 0 && <p>No sessions found</p>}
        </div>}
        {waterProfiles === null && <p>Loading...</p>}
    </div>)
}
