import React, {useEffect, useRef} from 'react';
import * as d3 from 'd3';

export function generateNormalData(mean, stdDev, points = 100) {
    const data = [];
    for (let i = 0; i < points; i++) {
        const x = mean + (i - points / 2) * (stdDev / points) * 6;
        const y = (1 / (stdDev * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));
        data.push({x, y});
    }
    return data;
}

export default function PlotNormalFunction({mean, std_deviation, wanted_width=800, wanted_height=400}) {
    const ref = useRef();

    useEffect(() => {
        const data = generateNormalData(mean, std_deviation);

        // Set up dimensions
        const margin = {top: 20, right: 30, bottom: 30, left: 40};
        const width = wanted_width - margin.left - margin.right;
        const height = wanted_height - margin.top - margin.bottom;

        // Remove previous svg content if any
        d3.select(ref.current).select('svg').remove();

        // Append new svg content
        const svg = d3.select(ref.current)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Set up scales
        const x = d3.scaleLinear()
            .domain(d3.extent(data, d => d.x))
            .range([0, width]);

        const y = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.y)])
            .nice()
            .range([height, 0]);

        // Create axes
        svg.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(x));

        svg.append('g')
            .call(d3.axisLeft(y));

        // Create line generator
        const line = d3.line()
            .x(d => x(d.x))
            .y(d => y(d.y));

        // Append the line to the svg
        svg.append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', 'steelblue')
            .attr('stroke-width', 1.5)
            .attr('d', line);
    }, [mean, std_deviation, wanted_width, wanted_height]);

    return <div ref={ref}></div>;
};
