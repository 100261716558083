import React, {useState} from 'react'
import './show-less-more.css'

export default function ShowLessMore({className, children}) {
    const [showMore, setShowMore] = useState(false);

    return (<div className={`show-less-more-wrapper ${className}`}>
        {<span className='show-less-more' onClick={() => setShowMore(!showMore)}>Show {showMore ? 'less -' : 'more +'}</span>}
        {showMore && children}
    </div>)
}
