import {http} from "../api";

const easyUnpack = ({data}) => data;
export const VideoApi = {
    list_available_videos: {
        f: function list_available_videos({boat_id = null}) {
            let url = '/video';
            return http.get(url, {params: {boat_id}}).then(easyUnpack)
        }
    },
    watch_video: {
        f: function watch_video(boat_id, camera_id, first_image_id) {
            let url = '/video/watch';
            return http.get(url, {params: {boat_id, camera_id, first_image_id}}).then(easyUnpack)
        }
    },
    fetch_latest_image: {
        f: function fetch_latest_image(camera_id) {
            let url = '/video/latest-image';
            return http.get(url, {params: {camera_id}}).then(easyUnpack)
        }
    },
    stream_old: {
        f: function stream_old() {
            let url = '/video/ffmpeg-stream-old';
            return http.post(url, {}).then(easyUnpack)
        }
    },
    stream: {
        f: function stream() {
            let url = '/video/ffmpeg-stream';
            return http.post(url, {}).then(easyUnpack)
        }
    },
};
