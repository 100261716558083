import React from "react";

export default function InputCheckbox({id, title, value, onChanged}) {
    return <div><label htmlFor={`#${id}`}>{title}
        <input
            id={`#${id}`}
            type='checkbox'
            checked={value}
            onChange={v => onChanged(v.target.checked)}/>
    </label>
    </div>
}
