import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {logout} from "../../auth/user-service";

export default function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        logout()
        navigate('/login')
    }, [navigate]);

    return <div>Redirecting to login</div>
}
