import * as ol from 'ol';
import {Point} from "ol/geom";

export function distanceBetweenLongitudes(lon1, lon2, latitude) {
    const R = 6371000; // Earth's radius in meters
    const deltaLon = lon2 - lon1;
    const latitudeRad = latitude * (Math.PI / 180); // Convert latitude to radians

    const distance = deltaLon * Math.cos(latitudeRad) * (Math.PI / 180) * R;
    return Math.abs(distance);
}


export function calculateDistanceInKmBetweenLatLon(lattitude1, longittude1, lattitude2, longittude2) {
    const toRadian = n => (n * Math.PI) / 180

    let lat2 = lattitude2
    let lon2 = longittude2
    let lat1 = lattitude1
    let lon1 = longittude1

    let R = 6371  // km
    let x1 = lat2 - lat1
    let dLat = toRadian(x1)
    let x2 = lon2 - lon1
    let dLon = toRadian(x2)
    let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c
}

export function NormalizeHeatmapData(extent, samples, indicatorName, indicatorMin, indicatorMax) {
    function createGrid(extent, gridSize) {
        const [minX, minY, maxX, maxY] = extent;
        const xStep = (maxX - minX) / gridSize;
        const yStep = (maxY - minY) / gridSize;
        const grid = [];

        for (let i = 0; i < gridSize; i++) {
            for (let j = 0; j < gridSize; j++) {
                grid.push({
                    xmin: minX + i * xStep,
                    xmax: minX + (i + 1) * xStep,
                    ymin: minY + j * yStep,
                    ymax: minY + (j + 1) * yStep,
                    centerx: minX + i * xStep + xStep / 2,
                    centery: minY + j * yStep + yStep / 2,
                    samples: [],
                });
            }
        }

        return grid;
    }

    function assignSamplesToGrid(grid, samples) {
        samples.forEach(sample => {
            const latitude = sample.get('latitude')
            const longitude = sample.get('longitude')
            const value = sample.get(indicatorName);
            for (let cell of grid) {
                const matchX = longitude >= cell.xmin && longitude < cell.xmax;
                const matchY = latitude >= cell.ymin && latitude < cell.ymax;
                if (matchX && matchY) {
                    cell.samples.push(value);
                    break;
                }
            }
        });
        return grid
    }

    function calculateNormalizedValues(grid) {
        grid.forEach(cell => {
            const sampleCount = cell.samples.length;
            const sumValues = cell.samples.reduce((sum, value) => sum + value, 0);

            const mean = sampleCount > 0 ? sumValues / sampleCount : 0;

            const normalizedMean = (mean - indicatorMin) / (indicatorMax - indicatorMin);

            cell.mean = normalizedMean
        });
    }

    function generateFeaturesFromGrid(grid) {
        const allfeats = grid.map(cell => {
            return new ol.Feature({
                geometry: new Point([cell.centerx, cell.centery]),
                value: cell.mean,
            });
        });
        // remove zero value
        return allfeats.filter(feat => feat.get('value') > 0);
    }

    const longitudeCovered = distanceBetweenLongitudes(extent[0], extent[2], extent[1]);

    const pointsPrkm = 150;
    let pointsTotal = pointsPrkm * longitudeCovered / 1000;

    pointsTotal = Math.max(pointsTotal, 30)
    pointsTotal = Math.min(pointsTotal, 100)

    let grid = createGrid(extent, pointsTotal);
    assignSamplesToGrid(grid, samples);
    calculateNormalizedValues(grid);
    return generateFeaturesFromGrid(grid)
}
