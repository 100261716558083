import {http} from "../api";

const easyUnpack = ({data}) => data;
export const SensorReadingApi = {
    record: {
        f: function record(boat_id, values, {sensor_id = null}) {
            let url = '/sensor-reading';
            return http.post(url, {boat_id, values, sensor_id}).then(easyUnpack)
        }
    },
    record_with_unit: {
        f: function record_with_unit(boat_id, values, {sensor_id = null}) {
            let url = '/sensor-reading/with-unit';
            return http.post(url, {boat_id, values, sensor_id}).then(easyUnpack)
        }
    },
    list_sensor_data: {
        f: function list_sensor_data({
                                         keys = null,
                                         timestamp_from = null,
                                         timestamp_to = null,
                                         offset = null,
                                         limit = null,
                                         only_in_water = null
                                     }) {
            let url = '/sensor-reading';
            return http.get(url, {
                params: {
                    keys,
                    timestamp_from,
                    timestamp_to,
                    offset,
                    limit,
                    only_in_water
                }
            }).then(easyUnpack)
        }
    },
    describe_measurements: {
        f: function describe_measurements({keys = null}) {
            let url = '/sensor-reading/description';
            return http.get(url, {params: {keys}}).then(easyUnpack)
        }
    },
    most_recent_data: {
        f: function most_recent_data({keys = null}) {
            let url = '/sensor-reading/most-recent';
            return http.get(url, {params: {keys}}).then(easyUnpack)
        }
    },
    create_single_img: {
        f: function create_single_img(camera_id, image) {
            let url = '/sensor-reading/single';
            return http.post(url, {camera_id, image}).then(easyUnpack)
        }
    },
};
