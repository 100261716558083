import {http} from "../api";

const easyUnpack = ({data}) => data;
export const BoatImageApi = {
    create_single_img: {
        f: function create_single_img(camera_id, image) {
            let url = '/boat-image/single';
            return http.post(url, {camera_id, image}).then(easyUnpack)
        }
    },
    get_relevant_image: {
        f: function get_relevant_image(camera_id, wanted_timestamp, tolerance_seconds) {
            let url = '/boat-image/get-relevant-image';
            return http.get(url, {params: {camera_id, wanted_timestamp, tolerance_seconds}}).then(easyUnpack)
        }
    },
};
