import {http} from "../api";

const easyUnpack = ({data}) => data;
export const WaterProfileMetricApi = {
    create_water_profile_metric: {
        f: function create_water_profile_metric(water_profile_id, parameter, parameter_unit, mean, std_deviation) {
            let url = '/water-profile/$water_profile_id/metric';
            let parameterNames = ["$water_profile_id"];
            [water_profile_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {parameter, parameter_unit, mean, std_deviation}).then(easyUnpack)
        }
    },
    list_water_profile_metrics: {
        f: function list_water_profile_metrics(water_profile_id, {with_tags = null}) {
            let url = '/water-profile/$water_profile_id/metric';
            let parameterNames = ["$water_profile_id"];
            [water_profile_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {with_tags}}).then(easyUnpack)
        }
    },
    update_water_profile_metric: {
        f: function update_water_profile_metric(water_profile_id, metric_id, {
            parameter = null,
            parameter_unit = null,
            mean = null,
            std_deviation = null
        }) {
            let url = '/water-profile/$water_profile_id/metric/$metric_id';
            let parameterNames = ["$water_profile_id", "$metric_id"];
            [water_profile_id, metric_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.put(url, {parameter, parameter_unit, mean, std_deviation}).then(easyUnpack)
        }
    },
    delete_water_profile_metric: {
        f: function delete_water_profile_metric(water_profile_id, metric_id) {
            let url = '/water-profile/$water_profile_id/metric/$metric_id';
            let parameterNames = ["$water_profile_id", "$metric_id"];
            [water_profile_id, metric_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.delete(url, {data: {}}).then(easyUnpack)
        }
    },
    list_water_profile_metric_tags: {
        f: function list_water_profile_metric_tags(water_profile_id, metric_id) {
            let url = '/water-profile/$water_profile_id/metric/$metric_id/tags';
            let parameterNames = ["$water_profile_id", "$metric_id"];
            [water_profile_id, metric_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    create_water_profile_metric_tag: {
        f: function create_water_profile_metric_tag(water_profile_id, metric_id, tag_title, {
            lower_threshold = null,
            upper_threshold = null
        }) {
            let url = '/water-profile/$water_profile_id/metric/$metric_id/tags';
            let parameterNames = ["$water_profile_id", "$metric_id"];
            [water_profile_id, metric_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {tag_title, lower_threshold, upper_threshold}).then(easyUnpack)
        }
    },
    update_water_profile_metric_tag: {
        f: function update_water_profile_metric_tag(water_profile_id, metric_id, tag_id, tag_title, lower_threshold, upper_threshold) {
            let url = '/water-profile/$water_profile_id/metric/$metric_id/tags/$tag_id';
            let parameterNames = ["$water_profile_id", "$metric_id", "$tag_id"];
            [water_profile_id, metric_id, tag_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.put(url, {tag_title, lower_threshold, upper_threshold}).then(easyUnpack)
        }
    },
    delete_water_profile_metric_tag: {
        f: function delete_water_profile_metric_tag(water_profile_id, metric_id, tag_id) {
            let url = '/water-profile/$water_profile_id/metric/$metric_id/tags/$tag_id';
            let parameterNames = ["$water_profile_id", "$metric_id", "$tag_id"];
            [water_profile_id, metric_id, tag_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.delete(url, {data: {}}).then(easyUnpack)
        }
    },
};
