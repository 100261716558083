import React, {createContext, useState} from "react";

export const SessionContext = createContext(null);

export const SessionContextProvider = ({children}) => {
    const [session, setSession] = useState({});
    const [sessionData, setSessionData] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingVideos, setLoadingVideos] = useState(false);
    const [hoveringX, setHoveringX] = useState(null);
    const [selectedId, setSelectedId] = useState(null);

    return <SessionContext.Provider value={{
        hoveringX,
        setHoveringX,

        selectedId,
        setSelectedId,

        session,
        setSession,

        sessionData,
        setSessionData,

        loadingData,
        setLoadingData,

        loadingVideos,
        setLoadingVideos,
    }}>
        {children}
    </SessionContext.Provider>
}
