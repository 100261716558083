import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import TileLayer from 'ol/layer/Tile';
import VectorLayer from "ol/layer/Vector";
import {Point, LineString} from "ol/geom";
import VectorSource from "ol/source/Vector";
import {XYZ} from "ol/source";
import {Fill, Stroke, Style, Text} from "ol/style";
import CircleStyle from "ol/style/Circle";

import * as ol from 'ol';
import {toLonLat, useGeographic} from "ol/proj";
import {Heatmap as HeatmapLayer} from 'ol/layer';
import './proteus-map.css';
import chroma from 'chroma-js';
import {NormalizeHeatmapData} from "./NormalizeHeatmapData";
import useOlMap from "../ol-map/useOlMap";
import InputField from "../input-field/InputField";
import './proteus-map.css';
import InputCheckbox from "../InputCheckbox/InputCheckbox";
import {DragPan} from "ol/interaction";
import {SessionContext} from "../../page/historic-data/session/SessionContextProvider";
import cogWheel from '../../assets/cog-wheel.png';

const tileLayerFromUrl = (url) => new TileLayer({
    source: new XYZ({
        url,
        crossOrigin: 'anonymous'
    })
})

// Calculate the offset in map units
function calculateOffset(coord, offsetX, offsetY, map) {
    const resolution = map.getView().getResolution();
    const offsetLon = offsetX * resolution;
    const offsetLat = offsetY * resolution;
    return [coord[0] + offsetLon, coord[1] - offsetLat];
}

const downloadMapImage = (map) => {
    if (!map) return;

    map.once('rendercomplete', () => {
        const mapCanvas = document.createElement('canvas');
        const size = map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext('2d');
        Array.prototype.forEach.call(
            map.getViewport().querySelectorAll('.ol-layer canvas'),
            (canvas) => {
                if (canvas.width > 0) {
                    const opacity = canvas.parentNode.style.opacity;
                    mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                    const transform = canvas.style.transform;
                    // Get the transform parameters from the style's transform matrix
                    const matrix = transform.match(/^matrix\(([^\(]*)\)$/)[1].split(',').map(Number);
                    // Apply the transform to the context
                    mapContext.setTransform(matrix[0], matrix[1], matrix[2], matrix[3], matrix[4], matrix[5]);
                    mapContext.drawImage(canvas, 0, 0);
                }
            }
        );
        const link = document.createElement('a');
        link.href = mapCanvas.toDataURL();
        link.download = 'map.png';
        link.click();
    });
    map.renderSync();
};

export default function ProteusMap({
                                       sensorData,
                                       allow_pan = false,
                                       forceShiftkeyToZoom = false,
                                       displayKeys = [],
                                       height = '650px',
                                   }) {
    const [layers, setLayers] = useState({});
    const [popoutSettingsOpen, setPopoutSettingsOpen] = useState(false);
    const [heatmapGradient, setHeatmapGradient] = useState(null);

    const [flagHighlights, setFlagHighlights] = useState([]);
    const [userIsAddingHighlights, setUserIsAddingHighlights] = useState(false);

    const [hoveringMapFeatures, setHoveringMapFeatures] = useState([]);

    const [layerSettings, setLayerSettings] = useState({
        background: 'topographic',
        heatmap: false,
        feature_points: true,
        relative_values: true,
        big_map: false,
    });

    let hoveringX = null,
        setHoveringX = null,
        selectedId = null,
        setSelectedId = null;
    const context = useContext(SessionContext)
    if (context !== undefined) {
        hoveringX = context.hoveringX;
        setHoveringX = context.setHoveringX;

        selectedId = context.selectedId;
        setSelectedId = context.setSelectedId;
    }

    // Color indicator
    const [colorIndicator, setColorIndicator] = useState('faecal_coli');
    const [minIndicatorValue, setMinIndicatorValue] = useState(80);
    const [maxIndicatorValue, setMaxIndicatorValue] = useState(1000);

    const {
        initialized,
        map,
        mapElement,
        setInitialSourceRender,
        addLayer,
        removeLayer,
    } = useOlMap();

    useGeographic();

    const {indicatorMin, indicatorMax, values} = useMemo(() => {
        if (!layerSettings.relative_values) {
            return {
                indicatorMin: minIndicatorValue,
                indicatorMax: maxIndicatorValue,
            }
        }
        if (!sensorData || sensorData.length === 0) return {indicatorMin: 100, indicatorMax: 1000};
        let values = sensorData.map((point) => point.properties[colorIndicator]);
        values = values.sort((a, b) => a - b);
        return {
            indicatorMin: values[3],
            indicatorMax: values[values.length - 3],
            values,
        }
    }, [sensorData, colorIndicator, layerSettings, minIndicatorValue, maxIndicatorValue]);

    const mapMeasurementToColor = useCallback((value) => {
        const safeColor = '#85e89d';
        const severeColor = '#d9534f';

        if (value <= indicatorMin) return safeColor;
        if (value >= indicatorMax) return severeColor;

        // Calculate the interpolation factor
        const factor = (value - indicatorMin) / (indicatorMax - indicatorMin);

        // Interpolate color
        return chroma.mix(safeColor, severeColor, factor).hex();
    }, [indicatorMin, indicatorMax]);

    // display 'hold shift to zoom' popup
    const [showHoldShiftToZoomPopup, setShowHoldShiftToZoomPopup] = useState(false);

    useEffect(() => {
        if (showHoldShiftToZoomPopup) {
            setTimeout(() => {
                setShowHoldShiftToZoomPopup(false);
            }, 5000);
        }
    }, [showHoldShiftToZoomPopup]);

    useEffect(() => {
        if (!sensorData || !layers.featureLayer) return;

        const featureSource = layers.featureLayer.getSource();

        const features = sensorData.map((point) => {
            const properties = point.properties;
            const {latitude, longitude} = properties;

            const f = new ol.Feature({
                geometry: new Point([longitude, latitude]),
                latitude,
                longitude,
                ...properties,
                timestamp: new Date(properties.timestamp),
                id: point.id,
            })
            let color = 'red';

            color = mapMeasurementToColor(properties[colorIndicator]);

            f.setStyle(new Style({
                image: new CircleStyle({
                    radius: 4,
                    fill: new Fill({color}),
                    stroke: new Stroke({color: 'black', width: 1}),
                }),
                text: new Text({
                    font: '12px serif',
                    fill: new Fill({color: 'black'}),
                    stroke: new Stroke({color: 'black', width: 1}),
                    offsetY: 0,
                    padding: [2, 0, 0, 2]
                })
            }))
            return f
        });
        featureSource.clear()
        featureSource.addFeatures(features);

        setInitialSourceRender(featureSource);
    }, [sensorData, layers, mapMeasurementToColor, colorIndicator, setInitialSourceRender]);

    useEffect(() => {
        if (!sensorData || !layers.heatmapLayer || !layers.featureLayer) return;

        const featureSource = layers.featureLayer.getSource();

        const heatmapSource = layers.heatmapLayer.getSource();
        const heatmapFeatures = NormalizeHeatmapData(
            featureSource.getExtent(),
            featureSource.getFeatures(),
            colorIndicator,
            indicatorMin,
            indicatorMax,
        )
        heatmapSource.clear();
        heatmapSource.addFeatures(heatmapFeatures);

        setHeatmapGradient(layers.heatmapLayer.getGradient())

    }, [sensorData, layers, colorIndicator, indicatorMin, indicatorMax]);

    useEffect(() => {
        if (!flagHighlights || !layers.highlightLayer) return;
        const highlightSource = layers.highlightLayer.getSource();
        highlightSource.clear();
        const allFeatures = [];
        flagHighlights.forEach(highlight => {
            const offsetX = -100;
            const offsetY = -55;

            const [x, y] = toLonLat([offsetX, offsetY]);
            const textLatLonPos = calculateOffset([highlight.get('longitude'), highlight.get('latitude')], x, y, map);

            const f = new ol.Feature({
                geometry: new Point(textLatLonPos),
                is_highlight: true,
                parent_id: highlight.get('id'),
            })
            const props = [{
                key: 'faecal_coli',
                name: 'e.Coli',
            }, {
                key: 'temperature',
                name: 'Temp.',
            }, {
                key: 'turbidity',
                name: 'Turb.',
            }, {
                key: 'salinity',
                name: 'Sali.',
            }].map(({key, name}) => {
                const v = highlight.get(key)
                if (v === undefined) return ''
                return `${name} ${v}`
            }).join('\n')

            f.setStyle(new Style({
                text: new Text({
                    text: props,
                    font: '15px arial',
                    fill: new Fill({color: '#000'}),
                    stroke: new Stroke({color: '#333', width: 0.5}),
                    backgroundFill: new Fill({color: '#fff'}),
                    padding: [4, 4, 4, 4],
                }),
            }))

            const lineFromTextToPoint = new ol.Feature({
                geometry: new LineString([
                    textLatLonPos,
                    [highlight.get('longitude'), highlight.get('latitude')],
                ]),
            })
            lineFromTextToPoint.setStyle(new Style({
                stroke: new Stroke({
                    color: '#fff',
                    width: 2,
                    lineCap: 'round',
                }),
            }))

            allFeatures.push(f)
            allFeatures.push(lineFromTextToPoint)
        })
        highlightSource.addFeatures(allFeatures);
    }, [flagHighlights, layers, map]);

    useEffect(() => {
        if (layers.heatmapLayer) return;
        const featureSource = new VectorSource({
            crossOrigin: 'Anonymous',
            features: [],
        });
        const featureLayer = new VectorLayer({
            source: featureSource,
            crossOrigin: 'anonymous',
        });

        const highlightSource = new VectorSource({
            features: [],
        })
        const highlightLayer = new VectorLayer({
            source: highlightSource,
            crossOrigin: 'anonymous'
        });

        const heatmapFeatureSource = new VectorSource({
            features: [],
        })
        const heatmapLayer = new HeatmapLayer({
            source: heatmapFeatureSource,
            crossOrigin: 'anonymous',
            blur: 8,
            radius: 9,
            weight: function (feature) {
                return feature.get('value');
            },
        })

        const selectedDataPointSource = new VectorSource({
            features: [],
        })
        const selectedDataPointLayer = new VectorLayer({
            source: selectedDataPointSource,
            crossOrigin: 'anonymous',
        })

        setLayers(prev => ({
            ...prev,
            backgroundImagery: tileLayerFromUrl('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'),
            backgroundTopographic: tileLayerFromUrl('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'),
            heatmapLayer,
            featureLayer,
            highlightLayer,
            selectedDataPointLayer,
        }));
    }, [indicatorMin, indicatorMax, layers]);

    // Selected data point
    useEffect(() => {
        if (!selectedId || !layers.selectedDataPointLayer) return;
        const selectedDataPointSource = layers.selectedDataPointLayer.getSource();

        // Remove features with 'is_selected_datapoint' from source
        selectedDataPointSource.getFeatures().forEach(f => {
            if (f.get('is_selected_datapoint')) {
                selectedDataPointSource.removeFeature(f);
            }
        });

        const f = new ol.Feature({
            geometry: new Point([selectedId.properties.longitude, selectedId.properties.latitude]),
            ...selectedId,
            is_selected_datapoint: true,
        })
        f.setStyle(new Style({
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({color: 'rgba(255, 0, 0, 1)'}),
                stroke: new Stroke({color: 'black', width: 3}),
            }),
        }))
        selectedDataPointSource.addFeature(f)
    }, [selectedId, layers.selectedDataPointLayer]);

    // Hovering data point
    useEffect(() => {
        if (!layers.selectedDataPointLayer) return;
        const selectedDataPointSource = layers.selectedDataPointLayer.getSource();

        // Remove features with 'is_selected_datapoint' from source
        selectedDataPointSource.getFeatures().forEach(f => {
            if (f.get('is_hovering_datapoint')) {
                selectedDataPointSource.removeFeature(f);
            }
        });

        if (!hoveringX) return;

        const f = new ol.Feature({
            geometry: new Point([hoveringX.properties.longitude, hoveringX.properties.latitude]),
            ...hoveringX,
            is_hovering_datapoint: true,
        })
        f.setStyle(new Style({
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({color: 'rgba(0, 255, 0, 1)'}),
                stroke: new Stroke({color: 'black', width: 3}),
            }),
        }))
        selectedDataPointSource.addFeature(f)
    }, [hoveringX, layers.selectedDataPointLayer]);

    useEffect(() => {
        if (!initialized || !layers.featureLayer) return;

        const selectedLayers = [];
        if (layerSettings.background === 'imagery') {
            selectedLayers.push(layers.backgroundImagery)
        } else {
            selectedLayers.push(layers.backgroundTopographic)
        }

        if (layerSettings.heatmap) {
            selectedLayers.push(layers.heatmapLayer)
        }

        if (layerSettings.feature_points) {
            selectedLayers.push(layers.featureLayer)
        }

        if (selectedId) {
            selectedLayers.push(layers.selectedDataPointLayer)
        }

        selectedLayers.push(layers.highlightLayer)
        map.setLayers(selectedLayers);
    }, [layerSettings, layers, initialized, map, addLayer, removeLayer, selectedId]);

    useEffect(() => {
        if (!initialized) return;

        const cl = function (evt) {
            let foundFeature = null;
            map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                if (feature.get('is_highlight') === true) {
                    // delete feature
                    const parent_id = feature.get('parent_id');
                    setFlagHighlights(prev => prev.filter(f => f.get('id') !== parent_id));
                    return;
                }
                if (foundFeature !== null) return;
                foundFeature = feature
                const properties = feature.getProperties();
                console.log({
                    properties,
                    feature,
                })
                if (setSelectedId !== null) {
                    setSelectedId({properties, id: properties.id, timestamp_created: properties.timestamp});
                }
            });
            if (foundFeature !== null) {
                if (userIsAddingHighlights) {
                    setFlagHighlights(prev => {
                        console.log(foundFeature)
                        if (prev.includes(foundFeature)) {
                            return prev.filter(f => f !== foundFeature);
                        }
                        return [...prev, foundFeature];
                    })
                }
            }
        }

        const pointerMove = function (evt) {
            if (evt.dragging) {
                return;
            }
            const pixel = map.getEventPixel(evt.originalEvent);
            const hit = map.hasFeatureAtPixel(pixel);
            map.getTargetElement().style.cursor = hit ? 'pointer' : '';
        }

        const w = evt => {
            if (forceShiftkeyToZoom && !evt.originalEvent.shiftKey) {
                setShowHoldShiftToZoomPopup(true);
                evt.stopPropagation();
                return;
            }
            setShowHoldShiftToZoomPopup(false);
        }

        map.on('click', cl)
        map.on('pointermove', pointerMove);
        map.on('wheel', w);

        return () => {
            map.un('click', cl);
            map.un('pointermove', pointerMove);
            map.un('wheel', w);
        }
    }, [initialized, map, displayKeys, forceShiftkeyToZoom, userIsAddingHighlights, setSelectedId]);

    useEffect(() => {
        if (!initialized) return;
        map.getInteractions().forEach(interaction => {
            if (interaction instanceof DragPan) {
                map.removeInteraction(interaction);
            }
        })
        if (allow_pan) {
            map.addInteraction(new DragPan());
        }
    }, [initialized, map, allow_pan]);

    useEffect(() => {
        if (!initialized) return;
        const sour = layers.featureLayer.getSource()
        const weights = []
        const feats = sour.getFeatures().filter(foundFeature => {
            const ts = foundFeature.get('timestamp')
            // console.log(ts, hoveringX, ts - hoveringX)
            const diff = Math.abs(ts - hoveringX)
            if (diff < 1000) {
                weights.push(diff)
                return true
            }
            return false;
        })
        if (feats.length === 0) return
        const min = Math.min(...weights)
        const feat = feats[weights.indexOf(min)]

        setHoveringMapFeatures(prev => {
            prev.forEach(([f, oldStyle]) => f.setStyle(oldStyle))
            return [[feat, feat.getStyle().clone()]]
        })
    }, [hoveringX, layers, initialized]);

    return (<div className={`ol-map-parent ${layerSettings.big_map ? 'big-map' : ''}`}>
        <div style={{height: height}} className='ol-map-container' ref={mapElement}></div>

        {heatmapGradient && layerSettings.heatmap && <div className='heatmap-legend'>
            <label>{colorIndicator}</label>
            <div className='legend-colors' style={{
                background: `linear-gradient(to right, ${heatmapGradient.join(', ')})`,
            }}></div>
            <div className='legend-text'>
                <span>{values[0]}</span>
                <span>{values[values.length - 1]}</span>
            </div>
        </div>}

        <div className='popout-wrapper'>
            <button className='popout-btn' onClick={_ => setPopoutSettingsOpen(p => !p)}>
                <img src={cogWheel} alt='Settings'/>
            </button>
            <div className={`popout-settings ${popoutSettingsOpen ? 'open' : 'close'}`}>
                <InputField onlySelectableOptions
                            options={displayKeys}
                            value={colorIndicator}
                            onChanged={setColorIndicator}
                            title='Property'/>
                <InputField onlySelectableOptions
                            options={['topographic', 'imagery']}
                            value={layerSettings.background}
                            title={'Background'}
                            onChanged={v => setLayerSettings(prev => ({...prev, background: v}))}
                />

                <InputCheckbox
                    id={'heatmap'}
                    title='Heatmap'
                    value={layerSettings.heatmap}
                    onChanged={v => setLayerSettings(prev => ({...prev, heatmap: v}))}/>

                <InputCheckbox
                    id={'feature_points'}
                    title='Feature points'
                    value={layerSettings.feature_points}
                    onChanged={v => setLayerSettings(prev => ({...prev, feature_points: v}))}/>

                <hr/>
                <InputCheckbox
                    id='userIsAddingHighlights'
                    title='Add data highlights'
                    value={userIsAddingHighlights}
                    onChanged={v => setUserIsAddingHighlights(v)}/>

                <InputCheckbox
                    id='big-map'
                    title='Show big map'
                    value={layerSettings.big_map}
                    onChanged={v => setLayerSettings(prev => ({...prev, big_map: v}))}/>

                <hr/>
                <InputCheckbox
                    id='relative-values'
                    title='Relative values'
                    value={layerSettings.relative_values}
                    onChanged={v => setLayerSettings(prev => ({...prev, relative_values: v}))}/>
                {!layerSettings.relative_values && <>
                    <InputField type='number'
                                value={minIndicatorValue}
                                onChanged={setMinIndicatorValue}
                                title='Min indicator value'/>
                    <InputField type='number'
                                value={maxIndicatorValue}
                                onChanged={setMaxIndicatorValue}
                                title='Max indicator value'/>
                </>}
                <button onClick={_ => downloadMapImage(map)} style={{
                    padding: '10px 20px',
                    backgroundColor: '#007BFF',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>Download Map Image
                </button>
            </div>
        </div>
        {showHoldShiftToZoomPopup && <div className='hold-shift-to-zoom-popup'>
            <p>Hold shift to zoom</p>
        </div>}
    </div>)
}
